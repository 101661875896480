<template>
  <div class="rwg-app-single">
    <Review v-if="!loadingReview" :review="review" class="position-relative">
      <div v-if="review.reply || review.writing_reply" class="mt-4">
        <!-- eslint-disable-next-line-->
        <div class="d-flex align-items-center justify-content-between">
          <div class="w-100">
            <div v-if="review.waiting_4_ai || review.writing_reply && !review.editing">
              <div v-if="review.waiting_4_ai">
                Waiting for AI to write a reply <b-spinner small />
              </div>
              <!-- eslint-disable-next-line-->
              <TypeWriter v-else-if="!review.editing && review.reply" :typing-speed="35" :text="review.reply" @completed="review.stopped_typing = true" />
            </div>
            <textarea v-else-if="review.editing" v-model="review.reply" :disabled="!review.editing" class="form-control"></textarea>
            <div v-else>
              <strong class="d-block">Reply:</strong>
              {{review.reply}}
            </div>
            <div v-if="review.reply_updated" class="mt-2 text-danger">
              Posted to Google: {{review.reply_updated}}
            </div>
          </div>
          <div class="ml-2 h-25">
            <b-button v-if="!review.waiting_4_ai && review.stopped_typing" variant="dark" @click="review.editing = true">Edit</b-button>
          </div>
        </div>
      </div>
      <div>
        <b-button v-if="!review.waiting_4_ai" :disabled="review.working" size="sm" class="mt-2 mr-2" @click="createReply(review)">
          Write Reply
          <b-spinner v-if="review.working" small />
        </b-button>
        <b-button v-if="review.reply && !review.waiting_4_ai" variant="info" :disabled="review.working" size="sm" class="mt-2 mr-2" @click="rewriteReply(review)">
          Re-write Reply
          <b-spinner v-if="review.working" small />
        </b-button>
        <b-button v-if="review.reply && !review.waiting_4_ai" variant="outline-primary" :disabled="review.working" size="sm" class="mt-2 mr-2" @click="confirmPublish = true">
          Publish
          <b-spinner v-if="review.working" small />
        </b-button>
        <b-button v-if="review.reply && !review.waiting_4_ai" variant="danger" :disabled="review.working" size="sm" class="mt-2" @click="rejectReply = true">
          Reject
          <b-spinner v-if="review.working" small />
        </b-button>
      </div>
      <div v-if="review.openai_error" class="font-weight-bold text-danger">
        {{review.openai_error}}
      </div>
    </Review>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
    <b-modal v-model="confirmPublish" title="Publish Reply" size="lg">
      <p v-if="review">
        Are you sure you want to publish the following reply?
        <!-- eslint-disable-next-line-->
        <span class="d-block mt-3" v-html="review.reply"></span>
      </p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="confirmPublish = false"
        >Cancel</b-button
        >
        <b-button :disabled="review.working" variant="success" @click="publishReply(review)">
          Publish
          <b-spinner v-if="review.working" small />
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="rejectReply" title="Reject Reply" size="lg">
      <p v-if="review">
        Are you sure you want to remove the suggested reply from our system?
        <!-- eslint-disable-next-line-->
        <span class="d-block mt-3" v-html="review.reply"></span>
      </p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="rejectReply = false"
        >Cancel</b-button
        >
        <b-button :disabled="review.working" variant="danger" @click="rejectReview(review)">
          Remove
          <b-spinner v-if="review.working" small />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TypeWriter from '@components/Common/TypeWriter.vue';
import Review from "@components/Common/Review.vue";
export default {
  components:{
    TypeWriter,
    Review
  },
  data() {
    return {
      review: null,
      loadingReview:true,
      confirmPublish:false,
      rejectReply:false
    }
  },
  mounted() {
    this.getReview();
  },
  methods:{
    getReview(){
      this.$store
          .dispatch('reviews/getReview',this.$route.params.id)
          .then((resp) => {
            this.loadingReview = false
            this.review = resp;
            switch(this.$route.query.action){
              case 'approve':
                if(this.review.reply) {
                  this.confirmPublish = true;
                }else{
                  this.createReply(this.review);
                }
                break;
              case 'rewrite':
                if(this.review.reply) {
                  this.rewriteReply(this.review);
                }
                break;
              case 'reject':
                if(this.review.reply) {
                  this.rejectReply = true;
                }
                break;
            }
          })
          .catch((err) => {
            if(err.response.status === 403){
              this.$router.push({name:'business.dashboard'});
            }
            this.loadingReview = false
          })
    },
    rejectReview(review){
      review.working = true;
      this.$store
          .dispatch('reviews/rejectReply', {
            review: review
          })
          .then((res) => {
            this.review.reply = res.data.reply;
            this.rejectReply = false;
            review.working = false;
            review.editing = false;
            review.waiting_4_ai = false;
          })
          .catch(() => {
            review.working = false;
            review.editing = false;
            review.waiting_4_ai = false;
          })
    },
    publishReply(review){
      review.working = true;
      this.$store
          .dispatch('reviews/saveReply', {
            review: review
          })
          .then((res) => {
            review.working = false;
            review.editing = false;
            review.waiting_4_ai = false;
            this.confirmPublish = false;
            review.reply_updated = res.reply_updated;
          })
          .catch(() => {
            review.working = false;
            review.editing = false;
            review.waiting_4_ai = false;
          })
    },
    createReply(review){
      review.working = true;
      review.writing_reply = true;
      review.waiting_4_ai = true;
      review.stopped_typing = false;
      review.openai_error = null;
      review.editing = false;
      this.$store
          .dispatch('reviews/getReply', {
            review: review.review_text,
            reviewers_name: review.author_title,
            review_stars: review.review_rating+" out of 5 stars. "
          })
          .then((res) => {
            console.log(res.data);
            review.reply = res.data.reply;
            review.working = false;
            review.waiting_4_ai = false;
            if(res.data.error){
              review.openai_error = res.data.error;
            }
          })
          .catch(() => {
            review.working = false;
            review.writing_reply = false;
            review.waiting_4_ai = false;
            review.stopped_typing = true;
          })
    },
    rewriteReply(review){
      review.working = true;
      review.writing_reply = true;
      review.waiting_4_ai = true;
      review.stopped_typing = false;
      review.openai_error = null;
      review.editing = false;
      this.$store
          .dispatch('reviews/rewriteReply', {
            review: review.review_text,
            reviewers_name: review.author_title,
            review_stars: review.review_rating+" out of 5 stars. "
          })
          .then((res) => {
            console.log(res.data);
            review.reply = res.data.reply;
            review.working = false
            review.waiting_4_ai = false;
            if(res.data.error){
              review.openai_error = res.dada.error;
            }
          })
          .catch(() => {
            review.working = false;
            review.writing_reply = false;
            review.waiting_4_ai = false;
            review.stopped_typing = true;
          })
    }
  }
}
</script>
